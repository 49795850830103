.parallax-container {
    height: 450px;
}

@media only screen and (max-device-width: 992px) {
    .parallax-container {
        height: 300px;
    }
}

@media only screen and (max-device-width: 600px) {
    .parallax-container {
        height: 220px;
    }
}